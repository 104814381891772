import React, { useState, useEffect } from "react";
import api from "../api";
import CardModule from "../components/CardModuleOverview";

export default function Overview() {
  const [trips, setTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);

  const API_URL = process.env.REACT_APP_API;

  // ... rest of the component

  const readCards = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // console.log("page = ", page);
      const res = await api(
        `/api/cards?populate=*&sort=tripDate&pagination[page]=${page}&pagination[pageSize]=12`
      );
      // setPage((prevPage) => prevPage + 1);
      // console.log(res);

      const data = await res.data.data;
      setTrips((prevItems) => [...prevItems, ...data]);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   readCards();
  // }, []);

  useEffect(() => {
    readCards();
  }, [page]);

  const handleScroll = () => {
    const element = document.getElementById("root");

    if (
      window.innerHeight + document.documentElement.scrollTop + 1 * 0 <=
        element.offsetHeight - 200 ||
      isLoading
    ) {
      return;
    }

    setPage((prevPage) => prevPage + 1);

    // readCards();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  return (
    <div
      id="cardBox"
      style={{
        backgroundColor: "rgb(44 52 60)",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        padding: "2% 5%",
        // margin: "0% 5%"
        gap: "20px",
      }}
    >
      {trips.map((item) => {
        const attributes = item.attributes;
        // console.log(attributes.cardCover)
        // const ImageUrl = "http://localhost:1337" + attributes.cardCover.data.attributes.formats.large.url

        return (
          <div key={item.id}>
            <CardModule
              cardTitle={attributes.cardTitle}
              tripDate={attributes.tripDate}
              cardCover={attributes.cardCover}
              difficulty={attributes.difficulty}
              distance={attributes.distance}
              heightGain={attributes.heightGain}
              trackLink={attributes.trackLink}
              tripInfo={attributes.tripInfo}
              imageUrl={
                API_URL +
                // "http://localhost:1337" +
                attributes.cardCover.data.attributes.formats.medium.url
              }
            />
          </div>
        );
      })}

      {/* {isLoading && <p>Loading...</p>} */}
      {error && <p>Error: {error.message}</p>}
    </div>
  );
}
